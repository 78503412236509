App.Client = {
	clearTimeOuter: null,

	init: function() {
		App.setActiveNavBar(['clients']);
	},
	index: function() {
		var text = Cookies.get('text'),
			category_id = Cookies.get('category_id'),
			subcategory_id = Cookies.get('subcategory_id'),
			region_code = Cookies.get('region_code'),
			province_id = Cookies.get('province_id'),
			enabled = Cookies.get('enabled'),
			created_by = Cookies.get('created_by'),
			current_page = Cookies.get('current_page')
			;
		if(category_id == undefined || category_id == '')
		{
			category_id = '';
		}else{
			category_id = JSON.parse(category_id);
			$('#client-category-id').selectpicker('val', category_id).selectpicker('refresh');
		}
		$('#search').val(text);
		$('#client-subcategory-id').val(subcategory_id).selectpicker('refresh');
		if(region_code)
		{
			$('#client-region-code').val(region_code).selectpicker('refresh').trigger('change');
			App.getRelation('/cerca-provincia', $('#client-region-code'), $('#client-province-id'), function () {
				$('#client-province-id').val(province_id).selectpicker('refresh');
			});
		}
		$('#client-enabled').val(enabled).selectpicker('refresh');
		$('#client-created-by').val(created_by).selectpicker('refresh');
		App.Client.getClients(App.baseUrl + '/clients/get-clients?page=' + current_page + '' +
			'&term=' + text + '&category_id=' +
			'' + category_id + '&region_code=' + region_code + '&province_id=' +
			'' + province_id + '&enabled=' + enabled + '&created_by=' + created_by + '&subcategory_id=' + subcategory_id
		);
		$('body').on('click', '#pagination a', function(evt) {
			evt.preventDefault();
			var $this = $(this);
			Cookies.set('current_page', $this.html());
			App.Client.getClients($this.attr('href'));
		}).on('change', '#client-region-code', function() {
			App.getRelation('/cerca-provincia', $('#client-region-code'), $('#client-province-id'));
		}).on('change input',
			'#client-category-id, #search,' +
			'#client-region-code, #client-province-id,' +
			'#client-enabled, #client-created-by, #client-subcategory-id',
			function() {
			clearTimeout(App.Client.clearTimeOuter);
			App.Client.clearTimeOuter = setTimeout(function() {
				App.Client.search();
			}, 400);
		})
		;
	},
	search: function() {
		var current_page	= $('#pagination').find('.active span').html(),
			text			= $('#search').val(),
			category_id		= $('#client-category-id').val(),
			subcategory_id	= $('#client-subcategory-id').val(),
			region_code		= $('#client-region-code').find('option:selected').val(),
			province_id		= $('#client-province-id').find('option:selected').val(),
			enabled			= $('#client-enabled').find('option:selected').val(),
			created_by		= $('#client-created-by').find('option:selected').val()
			;

		current_page	= current_page ? current_page : 0;
		category_id		= category_id ? category_id : '';
		region_code		= region_code ? region_code : '';
		province_id		= province_id ? province_id : '';
		created_by		= created_by ? created_by : '';

		Cookies.set('text', text);
		Cookies.set('category_id', category_id);
		Cookies.set('subcategory_id', subcategory_id);
		Cookies.set('region_code', region_code);
		Cookies.set('province_id', province_id);
		Cookies.set('enabled', enabled);
		Cookies.set('created_by', created_by);
		App.Client.getClients(App.baseUrl + '/clients/get-clients?page=' + current_page + '' +
			'&term=' + text + '&category_id=' +
			'' + category_id + '&region_code=' + region_code + '&province_id=' +
			'' + province_id + '&enabled=' + enabled + '&created_by=' + created_by + '&subcategory_id=' + subcategory_id
		);
	},
	create: function() {
		var $clientProvince = $('#client-province-id'),
			$clientCity = $('#client-city-id')
			;
		App.getRelation('/cerca-comune', $clientProvince, $clientCity);

		$clientProvince.on('change', function() {
			App.getRelation('/cerca-comune', $clientProvince, $clientCity);
		});
	},
	edit: function() {
		App.Client.create();
	},
	getClients: function(url) {
		$.ajax({
			url: url,
			dataType: 'json',
			success: function(response) {
				if(response.success)
				{
					$('tbody').html(response.view);
					$('#pagination').html(response.pagination)
				}
			}
		});
	}
};
