var App = {
	init: function() {
		this.initDatepicker();
		this.initSelectpicker();
		this.initTimepicker();
		//this.initColorPicker();
		this.initSwitch();
		this.initAjaxEvent();
		this.initStateful();
		this.initSaveDialog();
		this.initDeleteDialog();
		this.toggleEnabled();
		this.initSaveDialog();
	},

	months: function()
	{
		return [
			'Gennaio',
			'Febbraio',
			'Marzo',
			'Aprile',
			'Maggio',
			'Giugno',
			'Luglio',
			'Agosto',
			'Settembre',
			'Ottobre',
			'Novembre',
			'Dicembre'
		];
	},

	days: function()
	{
		return [
			'Domenica',
			'Lunedì',
			'Martedì',
			'Mercoledì',
			'Giovedì',
			'Venerdì',
			'Sabato'
		];
	},

	daysShort: function()
	{
		return [
			'Dom',
			'Lun',
			'Mar',
			'Mer',
			'Gio',
			'Ven',
			'Sab'
		];
	},

	initDatepicker: function(){
		if (jQuery().datepicker) {
			$.fn.datepicker.dates['it'] = {
				days: ["Domenica", "Lunedì", "Martedì", "Mercoledì", "Giovedì", "Venerdì", "Sabato", "Domenica"],
				daysShort: ["Dom", "Lun", "Mar", "Mer", "Gio", "Ven", "Sab", "Dom"],
				daysMin: ["Do", "Lu", "Ma", "Me", "Gi", "Ve", "Sa", "Do"],
				months: ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"],
				monthsShort: ["Gen", "Feb", "Mar", "Apr", "Mag", "Giu", "Lug", "Ago", "Set", "Ott", "Nov", "Dic"],
				today: "Oggi",
				clear: "Svuota"
			};

			$('.datepicker').datepicker({
				orientation: "right",
				autoclose: true,
				language: 'it',
				todayHighlight: true,
				format: "dd-mm-yyyy",
				weekStart: 1,
				clearBtn: true
			});
		}
	},
	initSelectpicker: function() {
		if (jQuery().selectpicker) {
			$('.selectpicker').selectpicker({
				noneSelectedText: 'Nulla selezionato'
				//liveSearch: true
			});
		}
	},
	initTimepicker: function() {
		if (jQuery().timepicker) {
			$('.timepicker').timepicker({
				showMeridian: false,
				minuteStep: 10
			}).on('changeTime.timepicker', function(e) {
				var el = $(e.target);
				if(!el.hasClass('no-limit')) {
					var current = $.fullCalendar.moment(e.time.value, 'HH:mm');
					var start = $.fullCalendar.moment(centerTimeStart, 'HH:mm');
					var end = $.fullCalendar.moment(centerTimeEnd, 'HH:mm');
					if(current.isBefore(start)) {
						$(this).timepicker('setTime', centerTimeStart)
					}
					if(current.isAfter(end)) {
						$(this).timepicker('setTime', centerTimeEnd)
					}
				}
			});
		}
	},

	initColorPicker: function() {
		$('.color-picker').colorpicker();
	},

	initSwitch: function() {
		if (jQuery().bootstrapSwitch) {
			$('input.switch').bootstrapSwitch({
				onText: 'Sì',
				offText: 'No',
				onSwitchChange: function(event, state) {
					if(state == true) {
						$(this).attr('checked', '');
						$(this).val(1);
					} else {
						$(this).removeAttr('checked');
						$(this).val(0);
					}
				}
			});
		}
	},

	initDeleteDialog: function() {
		$(document).on('click', '.delete-dialog', function(e) {
			e.preventDefault();
			var t = $(this);

			swal({
				title: 'Vuoi eliminare la riga?',
				text: "La riga andrà persa per sempre!",
				type: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#d33',
				cancelButtonColor: '#3085d6',
				confirmButtonText: 'Sì, elimina!',
				cancelButtonText: 'Annulla'
			}).then(function(isConfirm) {
				if (isConfirm) {
					t.closest('form').submit();
				}
			})
		});
	},

	initAjaxEvent: function() {

		$.ajaxSetup({
			headers: { 'X-CSRF-Token' : $('input[name=_token]').val() }
		});
		$(document).ajaxError(function()
		{
			var errorDialog = '';
			errorDialog+= '<div class="modal-header">';
			errorDialog+= '<button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>';
			errorDialog+= '<h4 class="modal-title">Errore</h4>';
			errorDialog+= '</div>';
			errorDialog+= '<div class="modal-body">';
			errorDialog+= 'Ops! Si è verificato un errore. Chiudi la finestra e prova di nuovo.';
			errorDialog+= '</div>';
			errorDialog+= '<div class="modal-footer">';
			errorDialog+= '<button type="button" class="btn btn-default" data-dismiss="modal">Chiudi</button>';
			errorDialog+= '</div>';
			$('.create-item-modal').find('.modal-content').html(errorDialog);
			$('#modal-loader').hide();
		});
	},

	getRelation: function(url, startElement, destElement, callback) {
		var id = startElement.val(),
			value = destElement.data('value');

		if(id) {
			$.ajax({
				url: App.baseUrl + url,
				data: {
					id: id
				},
				dataType: 'json',
				beforeSend: function() {
					destElement.empty();
					var opt = document.createElement('option');
					$(opt).val('');
					$(opt).html('Caricamento in corso...');
					$(opt).attr('data-content','<i class=\'fa fa-refresh fa-spin\'></i> Caricamento in corso...');
					destElement.append(opt);
					destElement.selectpicker('refresh');
				},
				success: function(data) {

					destElement.empty();
					var opt = document.createElement('option');
					$(opt).val('');
					$(opt).html('Seleziona un valore');
					destElement.append(opt);

					if(data && data.length) {
						$.each(data, function(k, v) {
							var opt = document.createElement('option');
							$(opt).val(v.id);
							$(opt).html(v.val);
							if(value && v.id == value) {
								$(opt).attr('selected', 'selected');
							}
							destElement.append(opt);
						});
						destElement.selectpicker('refresh');
					}

					if(callback) {
						callback(data);
					}
				},
				complete: function() {
				},
				error: function(xhr, status, error) {
					alert('Si è verificato un errore durante la comunicazione con il server: ' + status); // + ' ' + error.getMessage()
				}
			});
		} else {
			destElement.empty();
			var opt = document.createElement('option');
			$(opt).val('');
			$(opt).html('Seleziona un valore');
			destElement.append(opt);
			destElement.selectpicker('refresh');
		}
	},

	initSaveDialog: function() {

		$('.modal-footer').on('click', '#save-modal', function(e)
		{
			e.preventDefault();
			e.stopPropagation();
			e.stopImmediatePropagation();

			var t = $(this);
			App.saveDialog(t);
		})
	},
	setActiveNavBar: function(actions)
	{
		actions.forEach(function(action){
			$('[data-action="' + action +'"]').addClass('active');
		})
	},
	initStateful: function() {
		$('.stateful').on('click', function () {
			$(this).button('loading')
		});

		$('input, select, textarea').bind('invalid', function() {
			$(this).closest('form').find('button[type="submit"]').button('reset');
		});
	},
	parseFails: function(data)
	{
		var ret = "";
		for(var key in data.data)
		{
			ret += data.data[key] + "\n";
		}
		return ret;
	},
	money: function(amount, prefix, suffix)
	{
		if(prefix)
		{
			return prefix + ' ' + App.numberFormat(amount, 2, ',', '.');
		}
		if(suffix)
		{
			return this.numberFormat(amount, 2, ',', '.') + ' ' + suffix;
		}
		return this.numberFormat(amount, 2, ',', '.');
	},
	numberFormat: function(number, decimals, dec_point, thousands_sep)
	{
		var n = !isFinite(+number) ? 0 : +number,
			prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
			sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
			dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
			toFixedFix = function (n, prec) {
				// Fix for IE parseFloat(0.55).toFixed(0) = 0;
				var k = Math.pow(10, prec);
				return Math.round(n * k) / k;
			},
			s = (prec ? toFixedFix(n, prec) : Math.round(n)).toString().split('.');
		if (s[0].length > 3) {
			s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
		}
		if ((s[1] || '').length < prec) {
			s[1] = s[1] || '';
			s[1] += new Array(prec - s[1].length + 1).join('0');
		}
		return s.join(dec);
	},
	toggleEnabled: function()
	{
		$('body').on('click', '.toggle-enabled', function(evt)
		{
			evt.preventDefault();
			var $item = $(this);
			var message;
			if($item.attr('data-confirm'))
			{
				message = $item.attr('data-confirm');
			}else{
				message = $item.hasClass('btn-success') ? 'Confermi la disabilitazione?' : 'Conferma l\'abilitazione?'
			}
			swal({
				title: message,
				type: 'question',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Sì',
				cancelButtonText: 'Annulla'
			}).then(function(isConfirm)
			{
				if(isConfirm !== false)
				{
					$.ajax({
						url: $item.attr('href'),
						type: 'POST',
						dataType: 'json'
					}).done(function(response)
					{
						if(response && response.status == 'fail')
						{
						}
						if(response && response.status == 'success')
						{
							if(response.data.enabled)
							{
								message = "Abilitato con successo";
							}else{
								message = "Disabilitato con successo";
							}
							swal(
								'Modificato!',
								message,
								'success'
							);
							$item.closest('tr').html(response.data.body);
						}
					});
				}
			});
		})
	},
	showError: function(message)
	{
		swal(
			'Errore!',
			message,
			'error'
		);
	}
};
