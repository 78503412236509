App.User = {
	init: function() {
		App.setActiveNavBar(['users']);
	},
	index: function() {
		$('body').on('click', '#pagination a', function(evt) {
			evt.preventDefault();
			App.User.getUsers($(this).attr('href'));
		});
	},
	create: function() {
		var $clientProvince = $('#user-province-id'),
			$clientCity = $('#user-city-id')
			;
		App.getRelation('/cerca-comune', $clientProvince, $clientCity);

		$clientProvince.on('change', function() {
			App.getRelation('/cerca-comune', $clientProvince, $clientCity);
		});
	},
	edit: function() {
		App.User.create();
	},
	getUsers: function(url)
	{
		$.ajax({
			url: url,
			dataType: 'json',
			success: function(response) {
				if(response.success) {
					$('tbody').html(response.view);
					$('#pagination').html(response.pagination)
				}
			}
		});
	}
};
